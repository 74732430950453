.toast-container {
  top: 100px !important; /* Adjust this value based on your navbar height */
}

.Toastify__toast-container {
  width: 320px;
}

.Toastify__toast--success {
  background-color: #4e8395 !important;
}

.Toastify__toast--error {
  background-color: #f7954e !important;
}

.signin-page .Toastify__toast--success {
  background-color: #4CAF50 !important;
}

.signin-page .Toastify__toast--error {
  background: #ff6b6b !important;
}

.Toastify__toast-body {
  font-family: your-font-family, sans-serif;
  font-size: 14px;
  color: #ffffff;
}
